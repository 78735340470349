import { getAction,deleteAction,putAction,postAction} from '@/api/manage'

const queryPermissionsByUser = (params)=>getAction("/api/sys/permission/getUserPermissionByToken",params);
// 获取产品菜单
const productionList = (params) => getAction('/api/tax/sys/product/list',params);
// 获取公用菜单
const getPubFunAndItem = (params) => getAction('/api/tax/sys/productPubFun/getPubFunAndItem',params);
// 检查是否绑定微信
const checkBindWxMq = (params) => getAction('/api/tax/client/user/checkBindWxMq',params);
// 企业信息管理
// 获取用户登录信息
const getLoginInfo = () => getAction('/api/tax/client/user/centre/getLoginInfo');
// 获取营业执照信息
const getCompanyIndentifyInfo = (params => getAction(`/api/tax/admin/enterprise/getEnterpriseAuthentication/${params}`));
// 查询所有省份地区
const getSysRegion = () => getAction(`/api/tax/admin/enterprise/getSysRegion`);
// 查询企业基本信息
const enterpriseBaseInfo = (params)=> getAction(`/api/tax/client/user/centre/getEnterpriseBasicInfo/${params}`);
// 查询行业
const getSysIndustry = (params) => getAction('/api/tax/admin/enterprise/getSysIndustry', params);
// 获取发票抬头信息
const getEnterpriseInvoiceInfo = () => getAction('/api/tax/client/enterprise/receipt/getEnterpriseInvoiceInfo');
// 获取发票内容
const getBusinessScopes = (params) => getAction('/api/tax/client/enterprise/receipt/getBusinessScopes',params);
// 获取发票默认内容
const getDefBusinessScope = () => getAction('/api/tax/client/enterprise/receipt/getDefBusinessScope');
// 获取邮寄地址
const getInvoiceMailed = (params) => getAction('/api/tax/client/enterprise/receipt/getInvoiceMailed',params);
// 获取邮寄地址
const addInvoiceMailed = (params) => postAction('/api/tax/client/enterprise/receipt/addInvoiceMailed',params);
// 查询业务员
const getEmployee = (params) => getAction('/api/tax/admin/enterprise/getSalesman',params);
// 修改企业信息
const updateEnterpriseBaseInfo = (params)=>putAction(`/api/tax/admin/enterprise/updateEnterpriseBaseInfo`, params);
// 获取结算信息
const getEnterpriseBankInfo = () => getAction('/api/tax/client/enterprise/getEnterpriseBankInfo');
// 获取限额额度
const getQuotas = () => getAction('/api/tax/client/enterprise/sign/getQuotas');
// 获取费率
const getServiceRule = () => getAction('/api/tax/client/enterprise/sign/getRebatePoint');
// 获取合同
const getContractInfo = () => getAction('/api/tax/client/enterprise/sign/getContractInfo');
// 修改登录密码
const updateLoginPwd = (params) => putAction('/api/tax/client/enterprise/account/updatePwd',params);
// 修改交易密码
const updatePayPwd = (params) => postAction('/api/tax/client/user/payPassword/update',params);
// 重置交易密码
const resetPwd = () => postAction('/api/tax/client/user/payPassword/forget');
// 重置登录密码
const resetPassword = (params) => putAction(`/api/tax/admin/enterprise/account/userCentreResetPassword?loginId=${params}`)
const getUserBindEmail = () => getAction('/api/tax/client/user/getUserBindEmail');
// 获取用户信息列表
const getUserInfoList = (params) => getAction('/api/tax/client/enterprise/account/getEnterpriseAccounts',params);
// 添加用户信息
const addEnterpriseAccount = (params) => postAction('/api/tax/client/enterprise/account/addEnterpriseAccount',params);
// 重置用户密码
const resetUserPwd = (params) =>putAction('/api/tax/client/enterprise/account/restPwd',params);
// 冻结
const freezeEnterpriseAccount = (params) => putAction('/api/tax/client/enterprise/account/freezeEnterpriseAccount',params);
// 解冻
const unLock = (params) => putAction('/api/tax/client/enterprise/account/unLock',params);
// 解绑微信
const unBindWxMq = (params) => getAction('/api/tax/client/user/unBindWxMq',params);
// 绑定微信
const getBindRQCode = (params) => getAction('/api/tax/client/user/getBindRQCode',params);
// 查询用户是否扫码绑定
const isChekSyS = (params) => getAction(`/api/tax/client/user/checkBind/${params}`);
// 企业门店信息列表
const enterpriseMerchantList =(params)=> getAction('/poly-pay/client/merchant/enterpriseMerchantList',params);
// 获取门店信息列表详情
const merchanGet = (params) => getAction(`/poly-pay/admin/merchant/queryById?id=${params}`);
// 门店图片查询
const getPictureByMerchantId = (params) => getAction(`/poly-pay/admin/merchantPicture/getPictureByMerchantId/${params}`);
// 获取订单列表
const getOrderList = (params) => getAction('/poly-pay/client/order/list',params);
// 订单详情
const getOrderDetail = (params) => getAction('/poly-pay/client/orderItem/list',params);
// 获取店铺列表
const getEnterpriseScopeList = (params) => getAction('/api/tax/admin/enterprise/business/scope/list',params);
// 查询关联门店
const getMchsByPage = (params) => getAction('/poly-pay/client/merchantStaffConfig/getMchsByPage',params)
// 查询关联门店
const merchantStaffConfig = (params) => postAction('/poly-pay/client/merchantStaffConfig/add',params);
// 删除关联门店
const merchantStaffConfigDel = (params) => postAction('/poly-pay/client/merchantStaffConfig/del',params);
// 设置默认关联门店
const merchantStaffConfigDef = (params) => postAction('/poly-pay/client/merchantStaffConfig/setDef',params);
// 获取任务列表
const raskList = (params) => getAction('/slash-youth/client/b/task/list',params);
// 获取任务人员列表
const taskWorker = (params) => getAction('/slash-youth/client/b/taskWorker/list',params);
// 服务人员配置添加
const merchantServiceConfig = (params) => postAction('/poly-pay/client/merchantServiceConfig/add',params);
// 服务人员配置列表
const merchantServiceConfigList = (params) => getAction('/poly-pay/client/merchantServiceConfig/list',params);
// 查询佣金列表
const getRebateData = (params) => getAction('/poly-pay/client/merchantServiceConfig/getRebateData',params);
// 获取门店下拉
const getServiceMerchantBox = (params) => getAction('/poly-pay/client/merchantServiceConfig/getServiceMerchantBox',params);
// 获取用户权限
const getRoleType = (params) => getAction('/api/tax/client/enterprise/trade/getRoleType',params);
// 生成结算单
const createSettlement = (params) => postAction('/poly-pay/client/merchantServiceSettlement/createSettlement',params);
// 获取结算报表
const merchantServiceSettlement = (params) => getAction('/poly-pay/client/merchantServiceSettlement/list',params);
// 获取结算报表明细
const merchantServiceSettlementDetail = (params) => getAction('/poly-pay/client/merchantServiceSettlement/getSettlementItemList',params);
// 企业统计
const getEnterpriseStatistics = (params) => getAction('/poly-pay/client/order/getEnterpriseStatistics',params);
// 获取树结构数据
const getFlow = (params) => getAction('/poly-pay/client/merchant/getFlow',params);
// 删除灵活用工
const delServiceUser = (params) => deleteAction('/poly-pay/client/merchantServiceConfig/delServiceUser',params);
// 首页流程图
const getFlowPaint = (params) => getAction('/poly-pay/client/merchant/getFlow',params);
export {
  getFlowPaint,
  delServiceUser,
  getFlow,
  getEnterpriseStatistics,
  merchantServiceSettlementDetail,
  merchantServiceSettlement,
  createSettlement,
  getRoleType,
  getServiceMerchantBox,
  getRebateData,
  merchantServiceConfigList,
  merchantServiceConfig,
  taskWorker,
  raskList,
  merchantStaffConfigDef,
  merchantStaffConfigDel,
  merchantStaffConfig,
  getMchsByPage,
  getEnterpriseScopeList,
  getOrderDetail,
  getOrderList,
  getPictureByMerchantId,
  merchanGet,
  enterpriseMerchantList,
  isChekSyS,
  checkBindWxMq,
  getBindRQCode,
  unBindWxMq,
  resetPassword,
  unLock,
  freezeEnterpriseAccount,
  resetUserPwd,
  addEnterpriseAccount,
  getUserInfoList,
  getUserBindEmail,
  resetPwd,
  updateLoginPwd,
  updatePayPwd,
  getQuotas,
  getServiceRule,
  getContractInfo,
  getEnterpriseBankInfo,
  updateEnterpriseBaseInfo,
  getEmployee,
  addInvoiceMailed,
  getInvoiceMailed,
  getDefBusinessScope,
  getBusinessScopes,
  getEnterpriseInvoiceInfo,
  getSysIndustry,
  enterpriseBaseInfo,
  getSysRegion,
  getLoginInfo,
  getCompanyIndentifyInfo,
  getPubFunAndItem,
  productionList,
  queryPermissionsByUser,
}