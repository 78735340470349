import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import less from 'less'
import '@/permission'
import Storage from 'vue-ls'
import config from '@/defaultSettings'
import * as echarts from 'echarts'

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(less)
Vue.use(Storage, config.storageOptions)
Vue.config.silent = true
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
