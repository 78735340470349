import router from './router'
import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"
import store from './store'
router.beforeEach((to, from, next) => {
  store.commit('SET_SLIDE',to.meta.slideMenu)
  if (to.meta.title) {
    if (to.meta.title) {
      document.title = '智赋云-' + to.meta.title
      let token = Vue.ls.get(ACCESS_TOKEN)
      if (to.path==='/') {
        next()
      } else {
        if (token) {
          next()
        } else {
          next({path:'/'})
        }
      }
    }
  }
})