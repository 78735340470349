import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录',
      slideMenu: false
    },
    component: () => import('../views/login/login')
  },
  {
    path: '/home',
    name: 'Login',
    meta: {
      title: '首页',
      slideMenu: false
    },
    component: Layout,
    children: [
      {
        path: '/home',
        meta: {
          title: '首页',
          slideMenu: false
        },
        component: () => import('../views/home/home')
      },
      {
        path: '/homePage',
        meta: {
          title: '首页',
          slideMenu: true
        },
        component: () => import('../views/firstPage/index')
      },
    ]
  },
  {
    path: '/polyPay',
    name: 'polyPay',
    meta: {
      title: '订单数据'
    },
    component: Layout,
    redirect: '/polyPay',
    children:[
      {
        path: '/polyPay',
        meta: {
          title: '订单数据',
          slideMenu: true
        },
        component: () => import('../views/payManage/index')
      },
      {
        path: '/polyPayDetail',
        name: 'polyPayDetail',
        meta: {
          title: '订单数据详情',
          slideMenu: true
        },
        component: () => import('../views/payManage/detail')
      },
    ]
  },
  {
    path: '/storeManage',
    name: 'storeManage',
    meta: {
      title: '门店列表'
    },
    component: Layout,
    redirect: '/storeManage',
    children:[
      {
        path: '/storeManage',
        meta: {
          title: '门店列表',
          slideMenu: true
        },
        component: () => import('../views/storeManage/index')
      },
      {
        path: '/storeManageDetail',
        meta: {
          title: '门店详情',
          slideMenu: true
        },
        component: () => import('../views/storeManage/detail')
      },
      {
        path: '/flexibleManage',
        meta: {
          title: '灵活用工',
          slideMenu: true
        },
        component: () => import('../views/flexibleManage/index')
      },
      {
        path: '/flexibleManageDetail',
        meta: {
          title: '灵活用工',
          slideMenu: true
        },
        component: () => import('../views/flexibleManage/components/lhygDetail')
      },
      {
        path: '/commissionManage',
        meta: {
          title: '佣金报表',
          slideMenu: true
        },
        component: () => import('../views/flexibleManage/commission')
      },
      {
        path: '/selttement',
        meta: {
          title: '结算列表',
          slideMenu: true
        },
        component: () => import('../views/flexibleManage/selttement')
      },
    ]
  },
  {
    path: '/accountManage',
    name: 'accountManage',
    meta: {
      title: '店员管理'
    },
    component: Layout,
    redirect: '/accountManage',
    children:[
      {
        path: '/accountManage',
        meta: {
          title: '店员管理',
          slideMenu: true
        },
        component: () => import('../views/accountManage/index')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
